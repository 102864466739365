const $ = require("jquery");
const toaster = require("react-toastify");

export const onInitialClientRender = () => {

  setTimeout(() => {
    if (window.klaro) {
      window.klaro.run();
    }
  }, 2000); // wait 2 seconds before charging klaro

  $(document).ready(function () {
    $(".menu-btn").click(function () {
      $("body").toggleClass("main");
    });
  });

  function start_nous_auto_rorate(rindex) {
    $("#rindex").val(rindex);
    window.sliderHasStarted = true;

    if (!window.sliderHasStarted) {
      // No rotation yet
      return;
    } else {
      let rindex = $("#rindex").val();
      window.teamSliderInter = setInterval(function () {
        if (rindex > numPhotoTeam) {
          rindex = parseInt(1);
        }

        let navItemSelector = `#nav_item_${rindex} > .nav-link`;
        let photoId = $(navItemSelector).attr("href");

        $(".nav-link").removeClass("active");
        $(".banner-section-main-contan").removeClass("show active");

        $(navItemSelector).addClass("active");
        $(photoId).addClass("show active");

        rindex = parseInt(rindex) + parseInt(1);
        $("#rindex").val(rindex);
      }, 10000);
    }
  }

  function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  // Team slider settings
  window.sliderHasStarted = false;
  const numPhotoTeam = 5;
  const rdmRotateNum = getRandomArbitrary(1, numPhotoTeam);
  start_nous_auto_rorate(rdmRotateNum);

  function testBlog(lang) {
    let msg;
    switch (lang) {
      case "en":
        msg = "Sorry, our blog is not available in English, yet.";
        break;
      case "es":
        msg =
          "Lo sentemos, nuestro blog no esta disponible en español, de momento";
        break;
      default:
        msg = "Unkown error";
        break;
    }
    toaster.toast.error(msg);
  }

  function frenchServiceOnly(lang) {
    let msg;
    switch (lang) {
      case "en":
        msg = "Sorry, this service is only available in French, yet.";
        break;
      case "es":
        msg =
          "Lo sentemos, este servicio sólo esta disponible en francés, de momento";
        break;
      default:
        msg = "Unknown error";
        break;
    }
    toaster.toast.error(msg);
  }

  // Adds the functions to the window object to make it available through navigation
  window.testBlog = testBlog;
  window.frenchServiceOnly = frenchServiceOnly;
};

export function shouldUpdateScroll(prevRouterProps, { location }) {
  if (prevRouterProps.routerProps.location.hash.length === 0) {
    window.scrollTo(0, 0);
    const body = document.getElementsByTagName("body")[0];
    body.scrollTop = 0;
    return false;
  } else {
    return true;
  }
}
